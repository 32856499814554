<template>
	<div id="warper">
		<el-card class="center">
			<div class="title">课程信息确认</div>
			<div class="fill_data">
				<div class="scimg">
					<el-image :src="defimgurl+info.imgPic" style="width:280px;height:158px;margin-right: 34px;">
					</el-image>
					<div>
						<div style="font-size: 22px;color: #101010;">{{info.course_name}}</div>
						<div style="font-size: 18px;color: #F90E0E;margin-top: 30px;">￥{{info.imoney}}</div>
					</div>
				</div>
				<!-- <div class="shrunk" style="border-bottom: 1px solid #eee; padding-bottom: 20px;padding-top: 5px;">
					<div style="color: #101010;font-size: 14px;width:87px;text-align: right;padding-right: 15px;">课程品类
					</div>
					<el-select v-model="value" placeholder="请选择">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div> -->
				<div style="border-bottom: 1px solid #eee; padding-bottom: 20px;padding-top: 5px;">
					<!-- <div class="shrunk">
						<div style="color: #101010;font-size: 14px;width:87px;text-align: right;padding-right: 15px;">
							机构优惠
						</div>
						<el-select v-model="value" placeholder="机构优惠1000元">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="shrunk">
						<div style="color: #101010;font-size: 14px;width:87px;text-align: right;padding-right: 15px;">
							平台优惠
						</div>
						<el-select v-model="value" placeholder="平台暂无优惠">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div> -->

					<div class="shrunk">
						<div style="color: #101010;font-size: 14px;width:87px;text-align: right;padding-right: 15px;">
							支付方式
						</div>
						<div style="display: flex;">
							<div :class="['zhifu', payment == item.value ? 'confirm' : '']"
								@click="payments(item.value)" v-for="item in paymentlist" :key="item.value">
								<img :src="item.pic" style="width: 24px;height: 24px;margin-right: 8px;" />
								<div>{{item.name}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="title" style="margin-top: 20px;margin-bottom: 25px;">需支付金额</div>
				<div class="price">
					<div>课程价格</div>
					<div>￥{{info.imoney}}</div>
				</div>
				<div class="price">
					<div>应付金额</div>
					<div>￥{{orderinfo.payMoney}}</div>
				</div>
				<div v-if="payment=='chinabankpc'">
					<div class="title" style="margin-top: 20px;margin-bottom: 25px;">上传支付凭证</div>
					<div>
						<el-upload class="avatar-uploader" :action="baseurl + '/Admin/Upload/uploadimage'"
							:headers="headers" accept="image/png, image/jpeg" :show-file-list="false"
							:on-success="uploadJFSuccess" :on-error="uploadimageerror">
							<img v-if="JFdata.payVucher" fit="contain" :src="defimgurl + JFdata.payVucher"
								class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</div>
				</div>
				<el-checkbox-group v-model="type">
					<el-checkbox label="同意兰溪人才网培训就业服务协议" name="type"></el-checkbox>
				</el-checkbox-group>
			</div>
			<div style="display: flex;width: 100%;justify-content: center;padding-top: 154px;padding-bottom: 45px;">
				<div class="baocun" @click="goto('/registrationUserInformation', { }, 1)">上一步</div>
				<div class="nextStep" @click="onSubmit">确认支付</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		getCourseDetail,
		addTrainingOrder,
		getTrainingOrder
	} from "@/api/home";
	import Header from "@/components/header.vue";
	import Footer from "@/components/footer.vue";
	import global from "@/assets/js/globalconfig";
	export default {
		components: {
			Header,
			Footer,
		},
		data() {
			return {
				defimgurl: global.baseimgurl,
				orderno: "",
				userinfo: "",
				payment: "wxpay",
				paymentlist: [{
						value: "wxpay",
						name: '微信支付',
						pic: require('@/assets/img/wx.png')
					},
					// 	{
					// 		value: "alipaypc",
					// 		name: '支付宝支付',
					// 		pic: require('@/assets/img/zfb.png')
					// 	},
					{
						value: "chinabankpc",
						name: '线下支付',
						pic: require('@/assets/img/yinhang.png')
					}
				],
				type: false,
				info: {},
				orderinfo: {}
			};
		},
		created() {
			this.orderno = this.$route.query.orderno;
			this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
			this.getTrainingOrder()
		},
		methods: {
			getTrainingOrder() {
				var _this = this;
				var par = {
					KW: this.orderno
				};
				getTrainingOrder(par).then((res) => {
					if (res.success) {
						if (res.data.length > 0)
							_this.orderinfo = res.data[0];
						_this.getCourseDetail(_this.orderinfo.course_id);
					}
				})
			},
			payments(v) {
				this.payment = v;
			},
			getCourseDetail(id) {
				var _this = this;
				var par = {
					id: id
				};
				getCourseDetail(par).then((res) => {
					if (res.success) {
						_this.info = res.data[0];
					}
				})
			},
			onSubmit() {
				var _this = this;
				_this.orderinfo.orderState = 2;
				_this.orderinfo.payState = 1;
				_this.orderinfo.payMent = _this.payment;

				if (_this.payment == "chinabankpc") {

				}
				addTrainingOrder(_this.orderinfo).then((res) => {
					if (res.success) {
						/*_this.$message({
							message: "报名成功！返回就业培训列表页面！",
							type: "success",
						});*/
						//this.goto('/trainingEmployment', {}, 1)
						this.goto('/orderPayment', {
							orderno: _this.orderno
						}, 1)
					} else
						_this.$message.error(res.msg);
				})
			},
			goto(url, data, type = 2) {
				this.resolvewin(url, data, type);
			},
		},
	};
</script>


<style lang="scss" scoped>
	.center {
		width: 1200px;
		margin: 29px auto;

		.title {
			color: #101010;
			font-size: 16px;
			text-align: left;
			font-family: SourceHanSansSC-regular;
		}

		.fill_data {
			margin-top: 22px;

			.scimg {
				display: flex;
				border-bottom: 1px solid #eee;
				padding-bottom: 20px;

				::v-deep .avatar-uploader .el-upload {
					border: 1px dashed #d9d9d9;
					border-radius: 6px;
					cursor: pointer;
					position: relative;
					overflow: hidden;
					margin-left: 16px;
					background-color: #F5F7FA;
				}

				::v-deep .avatar-uploader .el-upload:hover {
					border-color: #409EFF;
				}

				::v-deep .avatar-uploader-icon {
					font-size: 32px;
					color: #8c939d;
					width: 192px;
					height: 112px;
					line-height: 112px;
					text-align: center;
					position: relative;
				}

				::v-deep .avatar {
					width: 178px;
					height: 178px;
					display: block;
				}
			}

			.shrunk {
				display: flex;
				align-items: center;
				margin-top: 15px;

				::v-deep .el-input {
					width: 400px;
				}
			}

			.zhifu {
				color: #101010;
				font-size: 14px;
				text-align: left;
				font-family: SourceHanSansSC-regular;
				display: flex;
				align-items: center;
				cursor: pointer;
				width: 135px;
				height: 40px;
				line-height: 20px;
				text-align: center;
				border: 1px solid #ccc;
				justify-content: center;
				margin-right: 14px;
			}

			.zhifu.confirm {
				border: 1px solid #409EFF;
			}
		}

		.baocun {
			width: 200px;
			height: 36px;
			line-height: 36px;
			cursor: pointer;
			background-color: #fff;
			color: #0089FF;
			font-size: 14px;
			text-align: center;
			font-family: Arial;
			border: 1px solid #0089FF;
			margin-right: 75px;
		}

		.nextStep {
			width: 200px;
			height: 36px;
			line-height: 36px;
			cursor: pointer;
			background-color: #0089FF;
			color: #fff;
			font-size: 14px;
			text-align: center;
			font-family: Microsoft Yahei;

		}

		.price {
			width: 170px;
			display: flex;
			justify-content: space-between;
			color: #101010;
			font-size: 14px;
			text-align: left;
			font-family: SourceHanSansSC-regular;
			margin-bottom: 16px;
		}
	}
</style>
